import { defineStore, acceptHMRUpdate } from 'pinia';
import { defineAsyncComponent, ref } from 'vue';
import { useModal } from 'vue-final-modal';
import { createLogger, redirect } from '@/utils';
import { getMeetingById } from '@/api/meetings';
import { Meeting } from '@/api/meetings/types';
import { useRoomSockets } from '@/composables';
import { useAuthStore } from './auth';
import { useUserStore } from './user';
import { Member } from '@/api/members/types';
import { getMeetingMembers } from '@/api/members';

export const useRoomStore = defineStore('room', () => {
    const logger = createLogger('room');

    const authStore = useAuthStore();
    const userStore = useUserStore();
    const { vcsSocket, analyticsSocket } = useRoomSockets();

    const meetingRoom = ref<Meeting>();
    const members = ref<Record<string, Member>>({});

    const setMeetingRoom = async (meetingId: string) => {
        const response = await getMeetingById(meetingId);

        if (!response.success) {
            logger.error(response.message);
            return;
        }

        meetingRoom.value = response.data;
    };

    const enterRoom = async () => {
        await Promise.all([vcsSocket.connect(), analyticsSocket.connect()]);

        vcsSocket
            .on('onClientJoinedToLobby', async () => {
                vcsSocket.emit('JoinRoom', {
                    meeting_id: meetingRoom.value!.id,
                    token: `Bearer ${authStore.accessToken}`,
                });
            })
            .on('onClientJoined', (payload) => {
                vcsSocket.setReconnectCallback(async () => {
                    await authStore.refreshTokens();

                    vcsSocket.emit('Reconnect', {
                        meeting_id: meetingRoom.value?.id!,
                        token: `Bearer ${authStore.accessToken}`,
                        identity: `${userStore.profile?.id}`,
                    });
                });

                analyticsSocket.emit('JoinRoom', payload);
                analyticsSocket.setReconnectCallback(async () => {
                    await authStore.refreshTokens();

                    analyticsSocket.emit('JoinRoom', {
                        ...payload,
                        token: `Bearer ${authStore.accessToken}`,
                    });
                });
            })
            .on('onMembersUpdate', (data) => {
                members.value = data.reduce(
                    (acc, member) => ({
                        ...acc,
                        [member.user_id]: {
                            id: member.user_id,
                            nickname: member.nickname,
                            avatar: member.avatar_url ?? null,
                            role: member.role,
                            isChatBlocked: false,
                        },
                    }),
                    {},
                );
            });

        vcsSocket.emit('JoinToLobby', {
            meeting_id: meetingRoom.value!.id,
            token: `Bearer ${authStore.accessToken}`,
        });
    };

    const handleRoomDisconnect = () => {
        const meetingId = meetingRoom.value?.id;

        vcsSocket.emit('LeaveFromMeeting');
        vcsSocket.disconnect();
        analyticsSocket.disconnect();

        redirect({ name: 'MeetingsPage' }).then(() => {
            if (userStore.isOrganizer || !meetingId) {
                return;
            }

            useModal({
                component: defineAsyncComponent(
                    () => import('@/blocks/RatingModal.vue'),
                ),
                defaultModelValue: true,
                keepAlive: false,
                attrs: { meetingId },
            });
        });
    };

    const updateMembers = async () => {
        const meetingId = meetingRoom.value?.id!;

        const response = await getMeetingMembers(meetingId);

        if (!response.success) {
            logger.error(response.message);
            return;
        }
    };

    return {
        meetingRoom,
        members,
        vcsSocket,
        analyticsSocket,
        setMeetingRoom,
        updateMembers,
        enterRoom,
        handleRoomDisconnect,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRoomStore, import.meta.hot));
}
