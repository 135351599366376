import { ref } from 'vue';
import { createSharedComposable } from '@vueuse/core';

export interface Notification {
    id: string;
    type: 'success' | 'info' | 'warning' | 'error';
    summary: string;
    life?: number;
}

export const useNotifications = createSharedComposable(() => {
    const notifications = ref<Notification[]>([]);

    const addNotification = (notification: Omit<Notification, 'id'>) => {
        const id = crypto.randomUUID();
        notifications.value.push({ ...notification, id });

        if (notification.life) {
            setTimeout(() => {
                removeNotification(id);
            }, notification.life);
        }

        return () => removeNotification(id);
    };

    const removeNotification = (id: string) => {
        notifications.value = notifications.value.filter(
            (item) => item.id !== id,
        );
    };

    return {
        notifications,
        addNotification,
        removeNotification,
    };
});
