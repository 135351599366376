import { Member as SdkMember } from '@index5/face-detector';
import { MemberContext } from '@/interfaces/members';
import { Member } from '@/api/members/types';

export function mapMembers(members: SdkMember[]) {
    return members.reduceRight<Record<string, Member>>((arr, member) => {
        const { can_not_write = false } = member.context as MemberContext;

        return {
            ...arr,
            [member.profile.user_id]: {
                id: Number(member.profile.user_id),
                nickname: member.profile.nickname,
                avatar: member.profile.avatar_url,
                isChatBlocked: can_not_write,
                role: member.roles[0],
            },
        };
    }, {});
}
